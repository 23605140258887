
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.card, input {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bg-text{
  color: oklch(var(--bc));
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.navbar, footer{
  color: oklch(var(--bc));
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.card{
  transition: all 0.2s ease-in-out;

  color: oklch(var(--bc));
  background-color: oklch(var(--b3));

}
.card:hover{
  transform:  translate(0px, -5%);
  background-color: oklch(var(--p));
  color: oklch(var(--pc));
}

.active {
  background-color: oklch(var(--p));
  z-index: 999;
  transform: scale(1.1);
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 480px) {
  .vod-iframe {
      width: 100%;
      height: 200px; /* Adjust height according to your requirement */
  }
}

/* For small screens */
@media (min-width: 481px) and (max-width: 768px) {
  .vod-iframe {
      width: 100%;
      height: 300px; /* Adjust height according to your requirement */
  }
}

/* For medium screens */
@media (min-width: 769px) and (max-width: 976px) {
  .vod-iframe {
      width: 100%;
      height: 400px; /* Adjust height according to your requirement */
  }
}

/* For large screens */
@media (min-width: 977px) and (max-width: 1440px) {
  .vod-iframe {
      width: 100%;
      height: 500px; /* Adjust height according to your requirement */
  }
}

/* For extra large screens */
@media (min-width: 1441px) {
  .vod-iframe {
      width: 90%;
      height: 600px; /* Adjust height according to your requirement */
  }
}